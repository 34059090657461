<template>
  <div class="form-component">
    <label for=""> Organization </label>
    <InputNest
      size="12px"
      label="Google product id"
      :model="['attributes', 'googleProductId']"
      class="pa-0 my-2"
      style="box-shadow: none !important;"
    />
    <InputNest
      size="12px"
      label="Product type"
      :model="['attributes', 'productType']"
      class="pa-0 mb-2"
      style="box-shadow: none !important;"
    />
    <v-divider class="my-4"></v-divider>
    <div class="mb-2">
      <label class="fz-12">Niche</label>
      <v-combobox
        v-model.trim="$parent.attributes.niche"
        hide-selected
        multiple
        persistent-hint
        small-chips
        placeholder="Enter"
        hide-details
        append-icon=""
        :delimiters="[',']"
      >
        <template v-slot:selection="data">
          <v-chip
            close
            small
            label
            :key="data.item"
            :disabled="data.disabled"
            @click:close="data.parent.selectItem(data.item)"
          >
            {{ data.item }}
          </v-chip>
        </template>
      </v-combobox>
      <small>Add this product to a collection so it’s easy to find in your store.</small>
    </div>
    <!-- <InputNest size="12px" label="Niche" :model="['attributes', 'niche']" /> -->
    <InputNest
      size="12px"
      label="Vendor"
      :model="['attributes', 'vendor']"
      class="pa-0 mb-2"
      style="box-shadow: none !important;"
    />
    <InputNest
      size="12px"
      label="GTIN"
      :model="['attributes', 'gtin']"
      class="pa-0 mb-2"
      style="box-shadow: none !important;"
    />
    <v-divider class="my-4"></v-divider>
    <div>
      <label class="fz-12">Collections</label>
      <v-autocomplete @change="changeManual()" v-model="selected" :items="items" item-text="title" item-value="_id">
        <template v-slot:selection></template>
        <template v-slot:item="data">
          <!-- <template>
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template> -->
          <template>
            <v-list-item-content>
              <v-list-item-title>
                <span v-html="data.item.title"></span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <div class="d-flex align-center" style="flex-wrap: wrap; gap: 8px">
        <!-- hiện các collection auto -->
        <v-chip v-for="(item, ind) in displayCollectionAuto" :key="ind" small label multiple>
          {{ item.title }}
        </v-chip>
        <!-- hiện các collection manual khi select ở v-autocomplete ở trên -->
        <v-chip
          v-for="(item1, ind1) in selecedList"
          :key="`${ind1}${item1}`"
          @click:close="removeChip(item1)"
          close
          hide-selected
          small
          label
          multiple
        >
          {{ item1.title }}
        </v-chip>
      </div>
      <small>Add this product to a collection so it’s easy to find in your store.</small>
    </div>
    <div class="my-5">
      <v-divider></v-divider>
    </div>
    <div>
      <label class="fz-12">Tags</label>
      <v-combobox
        v-model.trim="$parent.attributes.tags"
        hide-selected
        multiple
        persistent-hint
        small-chips
        placeholder="Enter"
        hide-details
        append-icon=""
        :delimiters="[',']"
      >
        <template v-slot:selection="data">
          <v-chip
            close
            small
            label
            :key="data.item"
            :disabled="data.disabled"
            @click:close="data.parent.selectItem(data.item)"
          >
            {{ data.item }}
          </v-chip>
        </template>
      </v-combobox>
      <small>Add this product to a collection so it’s easy to find in your store.</small>
    </div>
    <v-divider class="my-5"></v-divider>

    <!-- <div>
      <label class="fz-12">Trademark</label>
      <v-checkbox v-model="isTradeMark" @change="ischeckTradeMark()"></v-checkbox>
    </div> -->
  </div>
</template>

<script>
import { collectionApi } from '@/apis/collection';
import { log } from 'handlebars';

export default {
  props: {
    collections: {
      type: Array,
      // default: [],
    },
    collectionAuto: {
      type: Array,
    },
  },
  data() {
    return {
      displayCollectionAuto: [],
      selected: null,
      collectionList: [],
      selecedList: [],
      isTradeMark: this.$parent.attributes.isTM || false,
    };
  },
  methods: {
    changeManual() {
      try {
        let findCollection = this.collectionList.find(c => c._id === this.selected);
        if (findCollection) {
          this.selecedList.push(findCollection);
        }
        this.$parent.attributes.collections = this.selecedList;
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },
    ischeckTradeMark() {
      this.$parent.attributes.isTM = this.isTradeMark;
    },
    removeChip(item) {
      // tìm index của item vừa click remove trong this.selecedList
      let findIndex = this.selecedList.findIndex(c => c._id === item._id);
      if (findIndex != -1) {
        this.selecedList.splice(findIndex, 1);
        this.$parent.attributes.collections = this.selecedList;
        // this.$parent.selectedCollections = this.selecedList;
      }
      this.selected = null;
      // console.log(this.selected);
    },
    remove(item) {
      const index = this.$parent.attributes.collections.indexOf(item._id);
      if (index >= 0) this.$parent.attributes.collections.splice(index, 1);
    },
    async getDataCollection() {
      let res = await collectionApi.get();
      // lấy tất cả collection đang tồn tại
      this.collectionList = res.data.collections;
      let vm = this;
      // console.log(this.collectionList);
      // lấy các manual colection của sp từ props this.collections đổ từ $parent xuống cho vào this.selecedList
      if (this.collections) {
        for (let index = 0; index < vm.collections.length; index++) {
          const collection = vm.collections[index];
          this.selecedList.push(collection);
          // console.log(this.selecedList);
        }
      }
      if (this.collectionAuto) {
        this.displayCollectionAuto = this.collectionAuto;
      }
      // console.log(this.selecedList);
    },
  },
  computed: {
    items() {
      // lọc ra manual collection từ tất cả collection, cho vào combobox
      let list = this.collectionList.filter(
        c => c.collectionType === 'normal' && !this.selecedList.find(s => s._id === c._id),
      );
      // console.log(list);
      return list;
    },
  },
  created() {
    this.getDataCollection();
  },
};
</script>

<style lang="scss">
.label-checkbox {
  font-weight: none !important;
  text-decoration: none;
}
// collectionList là tất cả collection đang có
// selecedList là tất cả manual collection đã đc chọn của sản phẩm( tức là collectionList thì chứa selecedList)
// items là filter những col manual ở list trên combobox . this.collections = items + selecedList
</style>
