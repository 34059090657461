<template>
  <div class="form-component">
    <label for="">
      Shipping
    </label>
    <v-row gutters>
      <v-col cols="12">
        <label class="d-flex">WEIGHT </label>
        <p class="mb-1">Used to calculate shipping rates at checkout and label prices during fulfillment.</p>
        <v-row no-gutters>
          <v-col cols="4">
            <v-text-field
              v-model="$parent.variantDefault.weight"
              type="number"
              hide-details
              aria-autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pl-3">
            <v-select
              v-model="$parent.variantDefault.weightUnit"
              :items="['lb', 'oz', 'kg', 'g']"
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
