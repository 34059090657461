<template>
  <div class="pa-4 form-component">
    <div class="d-flex justify-space-between align-center">
      <label>Variants</label>
      <div @click="isShowAddVariants = !isShowAddVariants">
        <a class="link-add"> Add variant</a>
      </div>
    </div>
    <p>Add variants if this product comes in multiple versions, like different sizes or colors.</p>
    <transition name="fade">
      <v-row no-gutters v-if="isShowAddVariants" class="my-3">
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="mt-4">
          <v-row class="d-flex align-center">
            <v-col cols="3"><label for="">Option name </label></v-col>
            <v-col cols="6"> <label for="">Option values </label> </v-col>
          </v-row>
          <v-row v-for="(option, index) in options" :key="index" gutters class="d-flex align-center">
            <!-- <v-col cols="12">{{ options }}</v-col> -->
            <v-col cols="3"
              ><v-text-field v-model="options[index].name" hide-details="" aria-autocomplete="off"></v-text-field
            ></v-col>
            <v-col cols="6">
              <v-combobox
                v-model.trim="options[index].values"
                hide-selected
                :delimiters="[',']"
                multiple
                persistent-hint
                small-chips
                placeholder="Enter"
                hide-details
                append-icon=""
                @change="checkVariant(index)"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    label
                    close
                    :key="data.item"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <i v-if="options.length > 1" @click="removeOption(index)" class="pointer far fa-trash-alt"></i>
          </v-row>

          <v-data-table
            :headers="[
              { text: 'Variant', sortable: false },
              { text: 'Price', sortable: false },
              { sortable: false, text: 'SKU' },
              { sortable: false, text: 'Barcode' },
            ]"
            :items="$parent.listVariants"
            item-key="name"
            show-select
            disable-pagination
            hide-default-footer
            single-select
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td><v-checkbox v-model="item.active"></v-checkbox></td>

                  <td>{{ item.title }}</td>
                  <td><v-text-field type="number" v-model.number="item.price" name="name" id="id"></v-text-field></td>

                  <td><v-text-field type="text" v-model.number="item.sku" name="name" id="id"></v-text-field></td>

                  <td><v-text-field type="text" v-model.number="item.barCode" name="name" id="id"></v-text-field></td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-row no-gutters class="my-2">
            <v-btn outlined color="primary" v-if="options.length < 3" @click="addOption">Add another option</v-btn>
          </v-row>
          <!-- <v-btn @click="getListVariant" color="success">actions test</v-btn> -->
        </v-col>
      </v-row>
    </transition>
  </div>
</template>

<script>
import slugify from '@/helpers/slugify';

export default {
  data() {
    return {
      isShowAddVariants: false,
      text: '',
      variants: [],
    };
  },
  computed: {
    options: {
      set: function(options) {
        this.$parent.options = options;
      },

      get: function() {
        return this.$parent.options;
      },
    },
  },
  watch: {
    options: {
      handler: function() {
        this.getListVariant();
      },
      deep: true,
    },
  },
  methods: {
    checkVariant(i) {
      this.options[i].values = this.options[i].values.filter(b => b.trim() !== '');
    },
    /**
     * Add option
     */
    addOption(name) {
      this.options.push({
        name: typeof name === 'string' ? name : '',
        values: [],
      });
    },
    makeVariantId(variant) {
      let separator = '-variantidseparator-';
      return slugify((variant.option1 || separator) + (variant.option2 || separator) + (variant.option3 || separator));
    },
    getListVariant() {
      // Variant data
      let variantIds = [];
      // return ra các value input khác ''
      let getOptionValues = index => {
        // No option, no name or no values
        if (
          typeof this.options[index] === 'undefined' ||
          !this.options[index].name ||
          !this.options[index].values ||
          !this.options[index].values.length
        ) {
          return [];
        }

        return this.options[index].values;
      };

      // Option values groupped
      // mảng chứa các value input # '', mỗi cái getOptionValues(0) là 1 mảng, do là v-combobox
      let optionValues = [getOptionValues(0), getOptionValues(1), getOptionValues(2)];
      // số lượng điều kiện để tạo variant
      let optionValuesLength = optionValues.filter(item => item.length > 0).length;
      // Variant data generator
      this.$parent.listVariants = []; // set mảng giá trị cũ lại = [] để k bị trùng
      let variantInit = {
        barCode: this.$parent.variantDefault.barCode,
        sku: this.$parent.variantDefault.sku,
        price: parseFloat(this.$parent.variantDefault.price),
        compareAtPrice: parseFloat(this.$parent.variantDefault.compareAtPrice),
        costPerItem: parseFloat(this.$parent.variantDefault.costPerItem),
        taxable: this.$parent.variantDefault.taxable,
        isDefault: false,
        inventoryPolicy: this.$parent.variantDefault.inventoryPolicy,
        requiresShipping: this.$parent.variantDefault.requiresShipping,
        weightUnit: this.$parent.variantDefault.weightUnit,
        weight: this.$parent.variantDefault.weight,
        inventoryQuantity: this.$parent.variantDefault.inventoryQuantity,
      };
      let generate = () => {
        // optionValues[0] : value của mỗi 1 điều kiện là 1 mảng, do là v-combobox
        for (let i = 0; i < optionValues[0].length; i++) {
          if (optionValuesLength === 1) {
            this.$parent.listVariants.push({
              option1: `${optionValues[0][i]}`,
              active: true,
              ...variantInit,
              title: `${optionValues[0][i]}`,
            });
          }
          if (optionValuesLength === 2) {
            for (let j = 0; j < optionValues[1].length; j++) {
              this.$parent.listVariants.push({
                option1: `${optionValues[0][i]}`,
                option2: `${optionValues[1][j]}`,
                active: true,
                ...variantInit,
                title: `${optionValues[0][i]}/${optionValues[1][j]}`,
              });
            }
          }
          if (optionValuesLength === 3) {
            for (let j = 0; j < optionValues[1].length; j++) {
              for (let k = 0; k < optionValues[2].length; k++) {
                this.$parent.listVariants.push({
                  option1: `${optionValues[0][i]}`,
                  option2: `${optionValues[1][j]}`,
                  option3: `${optionValues[2][k]}`,
                  active: true,
                  ...variantInit,
                  title: `${optionValues[0][i]}/${optionValues[1][j]}/${optionValues[2][k]}`,
                });
              }
            }
          }
        }
      };
      generate(0);

      // Tooltip
    },
    removeOption(index) {
      this.options.splice(index, 1);
    },
  },
};
</script>
<style lang=""></style>
